import React, { useEffect } from 'react'
import GenericTablePage from '@templates/Generic/Table'
import useApi from '@hooks/useApi'
import { deleteSchedules, getSchedules } from '@utils/api'
import { Add, Delete } from '@mui/icons-material'
import { ButtonGroup } from '@mui/material'
import { chipRenderer } from '@utils/tables'
import { GridRowParams } from '@mui/x-data-grid-pro'
import { navigate } from 'gatsby'
import useLicense from '@hooks/useLicense'
import { Button } from '@components'

const ScanSchedulerPage = () => {
  const api = useApi({ apiMethod: getSchedules })
  const deleteApi = useApi<any>({
    apiMethod: deleteSchedules,
    requestOnMount: false
  })
  const { getLimit, refreshLimits } = useLicense()
  const count = api.response && api.response.data ? api.response.data.length : 0
  const limit = getLimit('schedules')

  useEffect(() => {
    refreshLimits()
  }, [])

  return (
    <GenericTablePage
      title="Scan Scheduler"
      actions={
        <ButtonGroup
          color="primary"
          variant="outlined"
          aria-label="Host Actions"
        >
          <Button
            feature={count >= limit ? 'schedules' : undefined}
            endIcon={<Add />}
            to="/scheduler/add/"
            data-cy="addSchedule"
          >
            Add Schedule
          </Button>
        </ButtonGroup>
      }
      breadcrumbs={[
        {
          title: 'Scheduler'
        }
      ]}
      api={api}
      gridOptions={{
        // name (default)
        // paused (default)
        // data_last_run (default)
        // date_next_run (default)
        // number_of_runs (default)
        // minutes_timer_low (default)
        // minutes_timer_high (default)
        // sandfly_selection_percent (default)
        // sandfly_type (default)
        // tags (default)

        columns: [
          {
            field: 'id',
            hide: true,
            type: 'enhancedString'
          },
          {
            field: 'name',
            headerName: 'Name',
            flex: 1,
            minWidth: 180,
            type: 'enhancedString'
          },
          {
            field: 'paused',
            headerName: 'Paused',
            type: 'boolean',
            width: 110
          },
          {
            field: 'date_last_run',
            headerName: 'Last Run',
            type: 'dateTime',
            width: 180
          },
          {
            field: 'date_next_run',
            headerName: 'Next Run',
            type: 'dateTime',
            width: 180
          },
          {
            field: 'number_of_runs',
            headerName: '# Runs',
            type: 'number',
            width: 100
          },
          {
            field: 'minutes_timer_low',
            headerName: 'Low Mins',
            type: 'number',
            width: 100
          },
          {
            field: 'minutes_timer_high',
            headerName: 'High Mins',
            type: 'number',
            width: 100
          },
          {
            field: 'sandfly_selection_percent',
            headerName: 'Selection',
            type: 'number',
            width: 100
          },
          {
            field: 'sandfly_type',
            headerName: 'Type',
            type: 'enhancedString',
            width: 470,
            renderCell: chipRenderer,
            sortable: false
          },
          {
            field: 'tags',
            headerName: 'tags',
            type: 'enhancedString',
            width: 250,
            renderCell: chipRenderer,
            sortable: false
          }
        ],
        onRowClick: (param: GridRowParams) => {
          navigate(`/scheduler/edit/${param.id}/`)
        }
      }}
      bulkActions={[
        {
          text: 'Delete Selected',
          icon: <Delete fontSize="small" />,
          action: async (selection: string[]) => {
            await deleteApi.makeRequest(selection).then(() => refreshLimits())
          },
          autoRefresh: true
        }
      ]}
    />
  )
}

export default ScanSchedulerPage
